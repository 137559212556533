
.expand-background {
    background-color: #91c0b6;
    //position: fixed;
    pointer-events: initial;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: .7s;
    transition-duration: .7s;
	animation-name:test;
    -webkit-transition-timing-function: cubic-bezier(.5,0,0,1);
    transition-timing-function: cubic-bezier(.5,0,0,1);
    overflow: hidden;
    //z-index: 60;
}

.page-ready .expand-background.animate::before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    transition-delay: 1.7s;
}

.expand-background::before {
    content: '';
    background-color: #f2efe8;
    -webkit-transform-origin: calc(50% - 26px) 50%;
    transform-origin: calc(50% - 26px) 50%;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    -webkit-transition-duration: 1.5s;
    transition-duration: 1.5s;
    -webkit-transition-timing-function: cubic-bezier(.5,0,0,1);
    transition-timing-function: cubic-bezier(.5,0,0,1);

	//bottom: 0;
	//width: inherit;
	//height: inherit;
    -o-object-fit: cover;
    object-fit: cover;
}

.pseudo-size-32::before {
	position: absolute;
	display: block;
	//top:0;
	//left: 0;
	width:100%;
	height: 32px;
}

.full-screen-div, .pseudo-full-screen::before {
	position: absolute;
	top:0;
	left: 0;
	right:0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
}

@keyframes logoPartLeft {
  0% {
    left:100%;
  }
  100% {
    left:0%;
  }
}

@keyframes logoPartRight {
  0% {
    left:calc(-100% - 1.4px);
  }
  100% {
    left:0;
  }
}


.name-logo-fast .logo-home.part-left>div {
	//animation-delay: 0.5s;
    animation-duration: .7s;
    transition-duration: .7s;
    transition-timing-function: cubic-bezier(.3,.5,.001,.00001);
}

.name-logo-fast .logo-home.part-right>div {
	animation-delay: .4s;
    animation-duration: .9s;
    transition-duration: .9s;
    transition-timing-function: cubic-bezier(.5,0,0,1);
}

.name-logo-slow .logo-home.part-left>div {
	animation-delay: 0.6s;
    animation-duration: .6s;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(.3,.5,.01,.001);
}

.name-logo-slow .logo-home.part-right>div {
	animation-delay: 1.4s;
    animation-duration: 1.1s;
    transition-duration: 1.1s;
    transition-timing-function: cubic-bezier(.3,.5,.01,.001);
}

.logo-home.part-left>div {
	position: relative;
    left:calc(100%);
	transition-property: left;
    animation-name: logoPartLeft;
    animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

.logo-home.part-right>div {
	position: relative;
    animation-name: logoPartRight;
    left:calc(-100%);
	transition-property: left;
    animation-iteration-count: 1;
	animation-fill-mode: forwards;
}



.logo-home.part-left {
	overflow: hidden;
	//padding-right: 1.4px;
}

.logo-home.part-right {
	//padding-left: 1.4px;
	overflow: hidden;
}
