//Custom

html, body, #root {
	height:100%;
	//overflow:hidden !important;;
	background-color: $fiduc-background;
}

textarea {
	border-style: solid !important;
}

#root {
//	display: flex;
}

:root {
	--bs-fiduc-cp-cut: #{$fiduc-cp-content-bottom-cut};
	--bs-fiduc-bg: #{$fiduc-background};
	--bs-fiduc-green: #{$fiduc-button-active};
	--bs-fiduc-green-light: #{$fiduc-green-light};
	--bs-fiduc-black: #{$fiduc-black};
	--bs-fiduc-neutral: #{$fiduc-neutral};
	--bs-fiduc-blue: #{$fiduc-blue};

	--bs-fiduc-liberty-blue: #{$fiduc-liberty-blue};
	--bs-fiduc-liberty-dark-blue: #{$fiduc-liberty-dark-blue};
	--bs-fiduc-liberty-dark-gray: #{$fiduc-liberty-dark-gray};


	--bs-fiduc-gray-dark: #{$fiduc-gray-dark};
	--bs-fiduc-gray-light: #{$fiduc-light-gray};
}

.text-fiduc-blue {
	color:$fiduc-blue;
}

.bg-gray {
	background-color: #efeded;
}

.z-index-0 {z-index: 0}
.z-index-1 {z-index: 1}
.z-index-2 {z-index: 2}
.z-index-3 {z-index: 3}
.z-index-5 {z-index: 4}

svg {
  fill: currentColor !important;
}

.form-field-type-money.form-field-empty-value INPUT:not(:focus) {
	color:white !important;
}

.btn-link-orange {
	color:$fiduc-orange !important;
}

.btn-link-black {
	color:$fiduc-black !important;
}

.bg-success {
  background-color: $fiduc-success !important;
}

.bg-warning {
  background-color: $fiduc-warning !important;
}

.bg-danger {
  background-color: $fiduc-danger !important;
}

.force-overflow-hidden {
	overflow: hidden;
}

BODY {
	//overflow:hidden !important;
	background-color: $fiduc-background;
}

.page-content-full-height {
	position: relative;
	padding: 1em calc(24px / 2) 0px calc(24px / 2) ;
	//overflow: auto !important;

	height: calc(100vh - calc(100vh - 100%)) !important;

	xborder:dotted 2px red;
}

.cursor-pointer {
	cursor:pointer;
}

.cursor-hand {
	cursor:grab;
}

.cursor-default {
	cursor:default;
}

.box-shadow-1 {
	box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.1);
}

.icon-circle {
	border-radius: 50%;
	empty-cells: show;
	width: 8px;
	height:8px;
	font-size:1px;
	line-height: 1px;
}

.text-overflow-ellipsis {
	white-space: nowrap;
	overflow:hidden;
	text-overflow:ellipsis;
}

.debug-on .debug{outline:solid 2px red;}
.debug-on .debug1{outline:solid 2px deeppink;}
.debug-on .debug2{outline:solid 2px green;}
.debug-on .debug3{outline:solid 2px yellow;}
.debug-on .debug4{outline:solid 2px #00FEFF;}
.debug-on .debug5{outline:solid 2px blue;}
.debug-on .debug6{outline:solid 2px #cbe4fb;}

.text-align-left {
	text-align: left !important;
}
.text-align-right {
	text-align: right !important;;
}
.text-align-center {
	text-align: center !important;;
}

.border-light {
	border-color:$fiduc-light-gray !important;
}

.border-dark {
	border-color:$fiduc-gray-dark !important;
}

.border-semi-dark {
	border-color:$fiduc-gray-semi-dark !important;
}

.btn:hover .spinner-grow {
	color:white !important;
}

.select2-selection__control {
	text-align: left;
}

.bg-fiduc-blue {
	background-color: $fiduc-blue !important;
	color:$white;
}

.bg-fiduc-dark-gray {
	background-color: $fiduc-gray-dark !important;
	color:$white;
}

.s2-options-color {
	color:transparent;
	border-radius: .25rem;
}

.text-transparent {
	color:transparent;
}

.bg-fiduc-neutral {
	background-color: $fiduc-neutral !important;
	color:$fiduc-black;
}

.bg-fiduc-background {
	background-color: $fiduc-background !important;
	color:$fiduc-black;
}

.bg-black {
	background-color: $fiduc-black !important;
	color:$white;
}


input.no-autofill-bkg:-webkit-autofill {
  -webkit-background-clip: text;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
    background-color: #FFFFFF !important;
    color: inherit !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: inherit !important;
	//border:inherit !important;
	font-weight:inherit !important;
	font-family:inherit !important;
}


.text-primary {
	color:$fiduc-text-secondary !important;
}

.text-secondary {
	color:$fiduc-text-secondary !important;
}

.text-danger {
	color:$fiduc-orange !important;
}

.text-gray-light {
	color:$fiduc-light-gray !important;
}

.text-gray-dark {
	color:$fiduc-gray-dark !important;
}

.text-gray {
	color:$fiduc-gray !important;
}

.text-danger LABEL, .invalid-feedback  {
	color:$fiduc-orange !important;
}



.btn-outline-primary:hover,
.btn-outline-danger:hover {
	color:white !important;
}

.btn-outline-primary:disabled,
.btn-outline-danger:disabled {
    opacity: 0.5;
 }

.btn-outline-primary,
.btn-outline-danger {
	border-color:rgba(0,0,0, 0.05) !important;
	color:$fiduc-black !important;;
 }

.form-check-input:checked {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e") !important;
}

.form-check-input {
	border-color:$black !important;
	width:1.25rem;
	height:1.25rem;
}

.form-check-input[type="checkbox"] {
	background-color: $fiduc-button-active !important;
	border-radius: 0rem !important;
}

.form-check-input[type="radio"] {
	background-color: #fff !important;
}

.form-check-input[type="radio"]:checked {
	background-color: $fiduc-button-active !important;
}

.form-field-type-select2 .form-control-container.is-invalid {
	border:solid 1px $fiduc-danger;
}

.form-check-label {
	margin-left:.5rem;
}

.radiogroup-centered .form-check {
	margin-left:.5rem;
	margin-right:.5rem;
	margin-top:.25rem;
	margin-bottom:.5rem;
	justify-content: center;
	display: flex;
	align-items: end;
	align-items: flex-end;

}

.form-check-label {
	margin-top: .5em !important;
	color: white !important;
}

.form-field {
	padding-right:.5rem;
	padding-left:.5rem;
}


.w-button-medium {
	min-width: 200pt;
}
.radiogroup-big .form-control .radio-group-items{
	background-color: $fiduc-background !important;
}

.radio-group-option .form-check INPUT {
	z-index:1;
}

.radiogroup-reverse .form-control .radio-group-option .form-check LABEL{
	width: 100%;
	margin-left: 0;
	margin-right: 0;
	padding-right: 2rem;
	padding-left: 0rem;
}

.radiogroup-reverse .form-control .radio-group-option .form-check{
	display: flex;
	flex-direction: row-reverse !important;
	padding-right: 1rem;
	padding-left: 1rem;
}

.radiogroup-big .form-check {
	background-color: $white !important;
}

.radiogroup-big .form-check LABEL {
	padding: 1rem 0rem 1rem 0rem ;
}

.radiogroup-big .form-control .radio-group-option{
	//padding:1rem 0rem 1rem 0.5rem;
	margin:0rem 0rem .5rem 0rem;
	padding: 0;
}

FIELDSET.form-control.form-control-container.radiogroup-big {
	background-color: $fiduc-background !important;
	border-color:transparent;
}

.form-field-type-title.condensed {
	//padding-bottom: 0px !important;
	//margin-bottom: 0px !important;
}

.radiogroup-centered .form-control .radio-group-items{
	display: flex;
	align-items: center;
	justify-content: center;
}

.radiogroup-centered .form-control {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.form-check {
	display: flex;
	align-items: center;
}

.form-field-empty-caption fieldset {
	padding-top:.5rem !important;
}

.font-size-title {
	font-size:1.5rem;
	@media (max-width: 720px) {
		font-size:1.5rem;
	}
}

ul {
	margin: 0;
}

ul.dashed {
	list-style-type: none;
}

ul.dashed > li {
	text-indent: -1rem;
}

ul.dashed > li:before {
	content: "-";
	margin-right: .5rem;
	text-indent: -1rem;
}


.will-change-page-full {
	will-change: transform, opacity
}

.fiduc-modal {
	overflow: hidden !important;
	animation-fill-mode: forwards !important;
	//animation:500ms ease 0s 1 normal none running opacityOut !important;
}

.fiduc-modal-container {
	padding:0 !important;
	margin: 0 !important;
	overflow-x: hidden !important;
}

#root>div>div[tabindex="0"] {
	//border:solid 10px red ;
	overflow:hidden !important;
}


.fiduc-modal>h2 {
	display: none;
}

.fiduc-modal>div {
	flex: 1 1 auto !important;
	display: flex;
	overflow: hidden;
}

.right-0 {
	right:0;
}

$w : 80px;
$h : 80px;

.success-animation {
	width: $w;
	height: $h;
	border-width: 4px;
	border-style: solid;
	border-color: rgb(76, 174, 76);
	border-radius: 50%;
	margin: 20px auto;
	position: relative;
	box-sizing: content-box;
}

.success-animation-1 {border-radius: 120px 0px 0px 120px; position: absolute; width: 160px; height: 100px; background: transparent; transform: rotate(-45deg); top: -7px; left: -33px; transform-origin: 60px 60px;}
.success-animation-2 {height: 5px; background-color: rgb(92, 184, 92); display: block; border-radius: 2px; position: absolute; z-index: 2; width: 25px; left: 14px; top: 46px; transform: rotate(45deg); animation: 0.75s ease 0s 1 normal none running animateSuccessTip;}
.success-animation-3 {height: 5px; background-color: rgb(92, 184, 92); display: block; border-radius: 2px; position: absolute; z-index: 2; width: 47px; right: 8px; top: 38px; transform: rotate(-45deg); animation: 0.75s ease 0s 1 normal none running animateSuccessLong;}
.success-animation-4 {width: 80px; height: 80px; border: 4px solid rgba(92, 184, 92, 0.2); border-radius: 50%; box-sizing: content-box; position: absolute; left: -4px; top: -4px; z-index: 2;}
.success-animation-5 {width: 5px; height: 90px; background-color: transparent; position: absolute; left: 28px; top: 8px; z-index: 1; transform: rotate(-45deg);}
.success-animation-6 {border-radius: 0px 120px 120px 0px; position: absolute; width: 60px; height: 120px; background: transparent; transform: rotate(-45deg); top: -11px; left: 30px; transform-origin: 0px 60px; animation: 4.25s ease-in 0s 1 normal none running rotatePlaceholder;}

.no-select {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.rounded-dot-25 {
	border-radius: .25rem !important;
}

.text-align-justify {
	text-align: justify;
}
