BODY.page-ready .form-field-animations-in {
    animation-name: slideIn;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 300ms;
}

BODY.page-ready .form-field-animations-out {
    animation-name: slideOut;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 300ms;
}

.form-group INPUT, SELECT, TEXTAREA {
  height:3.5rem;
}

.form-group INPUT[type=radio], .form-group INPUT[type=checkbox] {
  height:1.25rem;
  width:1.25rem;
}

.form-field-type-upload > .text-danger.form-group > :not(invalid-feedback-upload) {
  display:none;
}

.field-column {
	//margin-bottom: .5rem;
	//margin-top: .5rem;
}

.form-field-type-daterange INPUT.form-control {
	padding: 0px !important;
    margin: 0px !important;
    height: 26px;
    border: solid 0px red;
    background-color: white;
}


.form-field-type-password INPUT[type="text"] {
	font-family: 'password';
}

.form-field-type-password .password-visible INPUT[type="text"] {
	//font-family: unset !important;
}

.form-field-type-select select option:first-child
/*.form-field-type-select select:not(select[value])*/
{
  color: lightgray;

}
.form-field INPUT {
	padding-right: 0px !important;
}

.form-field-type-select select,
.form-field-type-date input
/*.form-field-type-radiogroup fieldset*/
{
	padding: 0.75rem .47rem 0.47rem .75rem;
}

.form-field-type-upload .invalid-feedback-upload .invalid-feedback {
  display: block;
  font-size: 0.6em;
  font-weight: initial;
}


.form-field-type-radiogroup fieldset>.form-control,
.form-field-type-checkgroup fieldset>.form-control
{
	background-color: inherit !important;
}

.form-field .form-check-label, .form-field FIELDSET {
	transition: none !important;
}

INPUT[type=checkbox] {
	min-width: 1.25rem;
}

.form-field-type-radiogroup FIELDSET.is-invalid,
.form-group INPUT.is-invalid,
.form-group TEXTAREA.is-invalid {
	transition:unset !important;
	border-style: solid;
	border-color: $fiduc-orange !important;
	//border-width: 0px !important;
}

.form-field INPUT[disabled],
.form-field TEXTAREA[disabled],
.form-field SELECT[disabled],
.form-field FIELDSET[disabled],
.form-field-type-select2 .form-control-container[readonly],
.form-field-type-select2multi .form-control-container[readonly]
{
	transition:  unset !important;
	background-color: $fiduc-input-bg-disabled !important;
	color:$fiduc-slate;
	opacity: $fiduc-disabled-text-opacity;
}



.form-field .form-check.disabled INPUT[type="radio"]:checked,
.form-field .form-check.disabled INPUT[type="checkbox"]:checked
{
	background-color: $fiduc-blue !important;
	opacity: $fiduc-disabled-text-opacity;
}


.dropzone[disabled] > * {
	cursor: default !important;
}

/*
  Google input style
*/
/*
INPUT.form-control {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-radius: 0;
  border-bottom-width: 2px;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  box-shadow: unset;
}

.form-control:focus, .form-control.is-valid:focus {
    border-color: $primary;
}
*/
.form-field-type-title {
	//animation: 360ms linear 0s 1 normal none running opacityIn;

    color: $fiduc-black;
    font-weight: 700;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.form-field-type-daterange DIV[id] {
	width: 100%;
}

.form-field-type-daterange .select2-selection__indicators.select-date-range-menu-options SVG {
	visibility: hidden !important;
	border:solid 1px red;
}

.form-field-type-daterange .select2-selection__value-container {
	max-height : 2em !important;
}

.form-field-type-daterange .select2-selection__control {
	    margin-right: 0;
}

.form-field-type-daterange .form-control-container {
	display: flex ;
    justify-content: flex-start ;
    flex-direction: row ;
}

.form-field-type-select2 INPUT[type="text"], .form-field-type-select2multi INPUT[type="text"] {
	//color:red !important;
	padding:0px 0px 0px 0px;
	margin:0px 0px 0px 0.25rem;
	height:1em !important;
	//border:solid 1px green !important;
}

.form-field-type-select2 .form-control.is-invalid,
.form-field-type-select2multi .form-control.is-invalid {
	min-height: 3em;
}

.form-field-type-select2 .form-control.is-invalid .select2-selection__indicator svg,
.form-field-type-select2multi .form-control.is-invalid .select2-selection__indicator svg
{
    fill: $fiduc-orange !important;
}

.form-field-type-select2 {
	height: 3.5rem !important;
	//max-height: 3.5rem !important;
}
.form-field-type-select2  .select2-selection__indicators {
	margin: -1rem -0.25rem 0px 0;
}

.form-field-type-select2  .is-invalid .select2-selection__indicators {
	display: none;
}

.select2-selection__dropdown-indicator {
	color:#fff !important;
}

.form-field-type-select2 .form-control-container ,
.form-field-type-select2multi .form-control-container {

}

.form-field-layout-floating-label {
    position: absolute;
    top: -10px;
    left: 20px;
    background-color: inherit;
    padding: 1px;
    pointer-events: none;
}

.floating-label-container {
  position: absolute;
  pointer-events: none;
  z-index: 1;

}

.form-field .form-field-floating-label-focus[disabled] {
	background-color:$white !important;
}


.form-field-error-container {
	position: absolute;
	pointer-events: none;
	z-index: 1;
}

.form-field-custom-error-message {
	text-transform: uppercase;
	color:$fiduc-danger;
	font-size: .5rem;
	margin-left: .5rem;
	font-weight: normal;

}

.form-field-error-message {
	//back
	// ground-color: $fiduc-danger;
	text-transform: uppercase;
	position:relative;
	color:$fiduc-danger;
	font-size: .5rem;
	margin-left: .5rem;
	font-weight: normal;
    top: 3.3rem;
	padding:.1rem .25rem ;
	border-radius: .14rem;
	border:solid 0px $white;
}

.form-field-type-textarea TEXTAREA.form-control.is-invalid {
	background-position: right calc( (var(--bs-gutter-x) * .5) + 0.5rem) top 1rem !important;
}
.form-field-type-textarea.form-field-empty-caption TEXTAREA {
	padding-top:1rem;
}
.form-field-type-textarea .form-field-error-message {
	top:5rem;
}

//.form-group > .form-field-layout-floating-label {
.form-field-floating-label-blur {
    transition:  0.3s ease;
    position: relative;
    top: 1.15rem;
    left: 1rem;
    overflow: hidden;
    padding: 0px 0px 0px 0px;
    border-radius: 6px;
    font-size: 1em;
    xpointer-events: none;
    color:$gray-600;
}

.form-field-type-select2 .form-field-floating-label-blur {
    top: 1.30rem;
}


//.form-group:focus-within > .form-field-layout-floating-label
.form-field-floating-label-focus
{
    transition:  0.3s ease;
    background-color:white;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    top: .725rem;
    left: 1rem;
    padding: 0px 0px 0px 2px;
    border-radius: 6px;
    font-size: 0.8em;
    xpointer-events: none;
}

.form-field-floating-label-focus[disabled],
.dropzone[disabled]
{
	background-color: $fiduc-input-bg-disabled;
}

.custom-form {
	height:100%;
}
.custom-form .form-control {
	border-color: $fiduc-input-border-color;
	padding-left:1rem;
	padding-right:1rem;
	padding-top: 1.5rem;
}

.form-field INPUT,
.form-field TEXTAREA,
.form-field SELECT,
.form-field .form-check-label,
.form-field FIELDSET,
.form-field-type-select2 .form-control-container,
.form-field-type-select2multi .form-control-container
{
	color:$fiduc-slate;
	font-weight: 400;
}

//.form-field INPUT,
//.form-field TEXTAREA,
//.form-field SELECT,
.form-field .form-check-label,
.form-field FIELDSET
{
	transition:  0.3s ease;
}

.form-field-type-select2 .form-control-container,
.form-field-type-select2multi .form-control-container
{
	transition:  none !important;
}


.form-field.form-field-disabled .icon  {
	display: none !important;
}

.form-field.form-field-disabled INPUT {
	border-color:$fiduc-neutral !important;
	background-color: $fiduc-neutral !important;
}

.form-field.form-field-disabled .form-field-floating-label-focus {
	background-color: transparent !important;
}

.form-field .icon I {
	background-color: white;
	font-size:1rem;
	z-index: 2;
	//min-width: 2.5rem;
    //text-align: center;
}

.form-field .icon {
	position:absolute;
	right:0;
	height:3.5rem;
	display:flex;
	align-items:center;
	justify-content:center;
	//padding-right:.7rem !important
	padding-right: calc( (var(--bs-gutter-x) * .5) + .6rem - .5rem) !important;
}


.was-validated .form-control:invalid, .form-control.is-invalid {
	//padding-right:0px !important;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
	background-position: right calc( (var(--bs-gutter-x) * .5) + .5rem ) center !important;
}


.form-field-type-radiogroup .form-control:invalid,
.form-field-type-radiogroup .form-control.is-invalid {
	//padding-right : 0 !important;
	//padding-left : 0 !important;
	//margin-right : 0 !important;
	//margin-left : 0 !important;
	background-image : unset !important;
	background-position : unset !important;
}

.form-field :-ms-input-placeholder,
.form-field ::-ms-input-placeholder,
.form-field ::placeholder
{
	color:white !important;
	opacity: 1; /* Firefox */
}
