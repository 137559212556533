.checkmark__circle {
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 4;
	stroke-miterlimit: 10;
	stroke: $black;
	fill: none;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
	width: 72px;
	height: 72px;
	border-radius: 50%;
	display: block;
	stroke-width: 4;
	stroke: $black;
	stroke-miterlimit: 10;
	box-shadow: inset 0px 0px 0px $fiduc-button-active;
	animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}
@keyframes scale {
	0%, 100% {
		transform: none;
	}
	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}
@keyframes fill {
	100% {
		box-shadow: inset 0px 0px 0px 36px transparent;
	}
}

// BUTTON RIPPLE EFFECT
[anim="ripple"] {

    //  Customize effect by defining the following
    //  variables on the parent element
    //  --ripple-background: white;
    //  --ripple-opacity: 0.3;
    //  --ripple-duration: 600ms;
    //  --ripple-easing: linear;

    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position:absolute;
        display: block;
        background: var(--ripple-background, white);
        border-radius: 50%;
        pointer-events: none;

        //  position and size
        top: calc(var(--y) * 1px);
        left: calc(var(--x) * 1px);
        width:  calc(var(--d) * 1px);
        height: calc(var(--d) * 1px);

        //  animated properties
        opacity: calc(var(--o, 1) * var(--ripple-opacity, 0.3));
        transition: calc(var(--t, 0) * var(--ripple-duration, 600ms)) var(--ripple-easing,linear);
        transform: translate(-50%, -50%) scale(var(--s, 1));
        transform-origin: center;
    }
}

.f-btn[anim="ripple"] {
    --ripple-background: white;
    --ripple-opacity: 0.3;
    --ripple-duration: 600ms;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    user-select: none;
}

.f-link[anim="ripple"] {
    --ripple-background: black;
    --ripple-opacity: 0.1;
    --ripple-duration: 600ms;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: background-color 300ms;
    &:hover { background-color: darken(whitesmoke, 3%);}
}
