
//
// Range slider
//

.irs {
  font-family: $font-family-base;
}

.irs--square{
  .irs-bar, .irs-to, .irs-from, .irs-single {
    background: $primary !important;
    font-size: 11px;
  }
  .irs-to, .irs-from, .irs-single{
    &:before{
      border-top-color: $primary;
    }
  }

  .irs-line{
    background: $gray-300;
    border-color: $gray-300;
  }
  .irs-grid-text{
    font-size: 11px;
    color: $gray-400;
  }
  .irs-min, .irs-max{
    color: $gray-400;
    background: $gray-300;
    font-size: 11px;
  }

  .irs-handle{
    border: 2px solid $primary;
    width: 12px;
    height: 12px;
    top: 26px;
    background-color: $card-bg !important;
  }
}


.rangeslider-horizontal .rangeslider__fill {
	background-color: black !important;
}


.rangeslider, .rangeslider .rangeslider__fill {
	box-shadow: unset !important;
}


.rangeslider {
	background-color: #b0b0b0;
}

.rangeslider-horizontal {
	height: 5pt;
}

.rangeslider-horizontal, .rangeslider__fill {
	border-radius: 1pt !important;
}

.rangeslider-horizontal .rangeslider__handle {
	width: 10pt;
	height: 10pt;
}

.rangeslider-horizontal .rangeslider__handle:after {
	display: none;
}


.rangeslider .rangeslider__handle {
	box-shadow: unset !important;
	background-color: black;
}

.rangeslider {
	margin: 0;
}

.rangeslider .rangeslider__handle {
	border: unset !important;
}
