.tabapp-container {
	height:3rem;
}

.app-nav-container-gestao {
	background-color: $fiduc-blue !important;
	color:$white;
}

.app-nav-container-liberty {
	background-color: $white !important;
	color:$black;
}

.tabapp-button-liberty {
	color:$black;
}

.tabapp-button-inactive {

}

.tabapp-container-liberty {
	//border:solid 4px gray;
}

.tabapp-button {
	font-weight: 500;
	transition: background-color 200ms ease-in;
 }

.tabapp-icon-image {
	transition:unset !important;
}

.tabapp-button-liberty:first-child {
	//border-left:solid 2px $fiduc-liberty-dark-gray;
}

.tabapp-button-liberty:last-child {
	border-right:solid 0px;
}

.tabapp-button-liberty {
	//border-top:solid 2px $fiduc-liberty-dark-gray;
	border-right:solid 1px $fiduc-liberty-gray;

	//border-bottom:solid 1px $fiduc-liberty-gray;
}

.tabapp-button-gestao.tabapp-button-active {
	background-color:#387d84;
	color:#fff;
}

.tabapp-button-liberty.tabapp-button-active {
	background-color:$fiduc-liberty-blue;
	color:$white;
}

.tabapp-icon-font {
	font-size:28px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.tabapp-icon-image {
	vertical-align: baseline;
}

.tabapp-button-gestao .tabapp-icon-image {
	height:28px;
	width:28px;
}

.tabapp-button-liberty .tabapp-icon-image {
	height:28px;
	width:28px;
}


.tabapp-button-disabled .tabapp-icon-image {
	filter:grayscale(100%) opacity(0.09);
}

.tabapp-button-disabled {
	pointer-events: none;
	color:#2c808b !important;
}

