.policy-container {
	bottom:0;
	position: fixed;
	display: flex;
	width: 100%;
	max-width: 100%;
    background-color: transparent;
	z-index: 4;
}

.policy-content {
	border-top-left-radius: 2pt;
	border-top-right-radius: 2pt;
	background-color: $white;
	opacity: .6;
	display: flex;
	flex-direction: row;

	width: 100%;
	@media (max-width: 1023px) {
		padding-bottom: .25rem;
		width: 100%;
	}
}

.header-container {
	//overflow: hidden;
	position: fixed;
	display: flex;
	width: 100%;
	max-width: 100%;
    background-color: transparent;
	padding-top:1.90rem;
	padding-bottom:0rem;

	align-items: center;
	justify-content: space-between;
	transition-property: padding;
	transition-duration: .7s;
	transition-timing-function: cubic-bezier(.5,0,0,1);
	z-index: 4;
}

.header-container.sticky {
	position: fixed;
	padding-top:1rem;
	padding-bottom:1rem;
}

.header-container::before {
    transition-property: background-color, padding;
    transition-duration: .7s;
    transition-timing-function: cubic-bezier(.5,0,0,1);
    overflow: hidden;
	position: absolute;
    top: 0;
    left: 0;
	max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header-container.sticky::before {
    transform: scaleY(1);
	max-width: 100%;
}

.header-container::before {
    content: '';
    background-color: #000;
    transform-origin: 50% 0;
    transform: scaleY(0);
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    transition-duration: .7s;
    transition-timing-function: cubic-bezier(.5,0,0,1);
    z-index: 1;
}

.header-logo-name {
	position:relative;
	margin: 0 0 0 -48px;
	@media (max-width: 1023px) {
		margin: 0 0 0 1.5rem ;
	}
}

.header-menu-container {
	justify-content: flex-end;
	align-items: center;
	transition-property: padding;
	transition-duration: .7s;
	transition-timing-function: cubic-bezier(.5,0,0,1);
	@media (max-width: 1023px) {
		align-items: flex-end !important;
	}
}

.sticky .header-menu-container {
	padding-bottom: 0rem;
	@media (max-width: 1023px) {
		padding-bottom: .25rem;
	}
}

.container-be-investor {
	padding:4rem 0 5rem 0;
	width: 30%;
	@media (max-width: 1023px) {
		padding:0 0 3rem 0;
		width: unset;
	}
}

.header-logo span {
	position: relative;
	//top:5px;
}

.sticky .header-logo span {
	top:0px;
}


.header-logo, .menu-items {
	justify-content: flex-start;
	align-items: center;
	transition-property: padding;
	transition-duration: .7s;
	transition-timing-function: cubic-bezier(.5,0,0,1);
}
.sticky .header-logo, .sticky .header-menu-container {
	align-items: center;
}

.header-logo {
	position: relative;
	margin-left:1.9rem;
}
.header-menu {
	position: relative;
	justify-content: flex-end;
}

.header-menu A {
	transition-property: color;
	transition-duration: .7s;
	transition-timing-function: cubic-bezier(.5,0,0,1);
}

.link-header, .link-header:focus, .link-header:active {
	color:#fff;
    transition-property: color;
    transition-duration: .7s;
    transition-timing-function: cubic-bezier(.5,0,0,1);}

.link-header:hover {
	color:$fiduc-green-light;
}
