.f-btn {
	border-radius: 1pt;
	padding: 0px 1.5rem 0px 1.5rem;
	font-weight: bold;
	height:60px;
	box-shadow: $box-shadow;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border: solid 1px transparent;
}

.f-btn-primary {
	background-color:$fiduc-button-active;
	color:$fiduc-black;
	border-color: $fiduc-button-active;
}

.f-btn-primary:active, .f-btn-primary:hover, .f-btn-primary:focus {
	background-color:$fiduc-button-active;
	color:$fiduc-black;
	border-color: $fiduc-button-active;
}

.f-btn-primary:disabled {
	color:$fiduc-black;
	opacity: .4;
	border-color: $fiduc-button-active;
}

.f-btn-icon {
	border:0;
	background-color: transparent;
}

.f-btn-icon:active, .f-btn-icon:hover, .f-btn-icon:focus {
	font-weight: 600;
}

.f-btn-icon:disabled {
	color:$fiduc-slate;
	opacity: .4;
	font-weight: unset;
	cursor: unset;
}

.f-btn-secondary.bordered {
	border: solid 1px black;
}

.f-btn-secondary {
	background-color:$white;
	color:$fiduc-black;
	border-color: $white;
}

.f-btn-secondary:active, .f-btn-secondary:hover, .f-btn-secondary:focus {
	background-color:$fiduc-button-active;
	color:$fiduc-black;
	border-color: $fiduc-button-active;
}

.f-btn-secondary:disabled {
	color:$fiduc-slate;
	opacity: .4;
}

.f-btn-medium {
	height:50px;
}
.f-btn-small {
	height:32px;
}



.f-link {
}


.f-link-menu LI {
	color:lighten($fiduc-black, 10);
}

.f-link-menu:hover LI, .f-link-menu:active LI, .f-link-menu:focus LI {
	color:darken($fiduc-black, 10);
}

.f-link-menu  {
	color:lighten($fiduc-black, 10);
	border-color: $fiduc-button-active;
	background-color: $white;
}

.f-link-menu:active, .f-link-menu:hover, .f-link-menu:focus {
	color:darken($fiduc-black, 10);
	border-color: $fiduc-button-active;
	background-color: $fiduc-button-active;
}

.f-link-menu:disabled {
	color:$fiduc-slate;
	opacity: .4;
	background-color: $white;
	border-color: $white;
}

.f-link-transparent {
	background-color: transparent;
}

.f-link-light {
	color:$white;
	border-color: $fiduc-button-active;
}

.f-link-light:active, .f-link-light:hover, .f-link-light:focus {
	color:$white;
	font-weight: 900;
	border-color: $fiduc-button-active;
}

.f-link-light:disabled {
	color:$fiduc-gray;
	opacity: .4;
	border-color: $fiduc-button-active;
}

li.mdi  {
	list-style: none;
}
li.di  {
	list-style: none;
}
