.button-menu {
	position: relative;
	width: 26px;
	height: 17px;
	background-color: transparent;
	border: none;
	padding: 0;
	cursor: pointer;
}

.button-menu span {
	position: absolute;
	left: 0;
	right: 0;
	height: .1rem;
}

.button-menu:hover span::before {
	background-color: $fiduc-green-light !important;
}

.button-menu span::before {
	content: ' ';
	background-color: white;
	transition-property: background-color, width;
	transition-duration: 0.7s;
	transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
	z-index: 1;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.button-menu span:nth-child(1) {
	top: 0;
}
.button-menu span:nth-child(2) {
	top: 8px;
}
.button-menu span:nth-child(3) {
	bottom: 0;
}

.button-menu span:nth-child(3)::before {
	width: 13px;
}

.button-menu:hover span:nth-child(1)::before {
	width: 33%;
}
.button-menu:hover span:nth-child(2)::before {
	width: 66%;
}
.button-menu:hover span:nth-child(3)::before {
	width: 100%;
}
