$split-page : 230pt;
$section-height : calc(100vh - (2rem + 33px));
//$section-height : 100vh;

//$width-sm : 540px; //1223px

html {
    position: relative;
    min-height: 100%;
}

html, body {
	height: 100%;
	width: 100%;
	margin:0;
	padding:0;
}

body {
	opacity:0;
	transition: opacity;
    overflow-x: hidden;
}

body.page-ready {
	transition: opacity .6s;
	opacity: 1;
}

body {
    position: relative;
    //overflow: hidden;
}

.section {
	display: block;
}

.section-intro {
	background-color: #000;
	position: relative;
	display: flex;
	width: 100vw;
	height: 100vh;
}

.section-intro .slogan-desktop {
	@media (max-height: 600px) {
		display: none !important;
	}
}


.section-values {
	background-color: gray;
	position: relative;
	width: 100vw;
	display: inline-flex;
	min-height: $section-height;
}

.section-values .content-left {
	background-color: #e3e1dc;
	//min-height:100vh;
}

.section-values .content-right {
	background-color: #f1efe9;
	@media (max-width: 1023px) {
		padding-top: 2rem;
	}

	//min-height:100vh;
}

.section-values .text-info-description {
	position: relative;
	left: -340pt;
	width:340pt;
	display: inline-block;
	text-align: right;
	padding-right: 3rem;
	top:0;
	font-size:10pt;
	@media (max-width: 1100px) {
		font-size: 6pt !important;
	}

}

.values-protection-min-height {
	padding-top: 1.8rem;
	padding-bottom: 1.8rem;
	@media (max-width: 1023px) {
		padding-bottom: unset;
		padding-top: unset;
	}
}
.values-proximity-min-height {
	padding-top: .1rem;
	padding-bottom: .1rem;
	@media (max-width: 1023px) {
		padding-bottom: unset;
		padding-top: 1.5rem;
	}
}
.values-alignment-min-height {
	padding-top: .1rem;
	padding-bottom: .1rem;
	@media (max-width: 1100px) {
		padding-bottom: unset;
		padding-top: 1.5rem;
	}
}

.audit-different {
	position: relative;
	left:-1rem;
	@media (max-width: 1100px) {
		position: unset;
		left:unset;
	}
}

.values-alignment-desc {
	margin-top: 0.75rem;
}

.section-values .text-info-container {
	//min-height: 4.7rem;
	padding-left: 1.5rem;
}

////

.section-beaffiliate {
	background-color: gray;
	position: relative;
	width: 100vw;
	display: inline-flex;
	min-height: $section-height;
}

.section-beaffiliate .content-left {
	background-color: #e3e1dc;
	//min-height:100vh;
}

.section-beaffiliate .content-right {
	background-color: #f1efe9;
	//min-height:$header-height;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.section-beaffiliate .content {
	padding-top: 1rem;
	column-gap: 20px;
	display: grid !important;
	grid-template-columns: auto auto auto;
	@media (max-width: 1023px) {
		padding-top: 1rem;
		grid-template-columns: auto;
	}
}


///
.section-simulation {
	position: relative;
	width: 100vw;
	display: inline-flex;
	min-height: $section-height;
}

.section-simulation .content-left {
	background-color: #b7c9cf;
	//min-height:$header-height;
}

.section-simulation .content-right {
	background-color: #c7dae1;
	//min-height:$header-height;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.section-simulation .range-item {
	min-width: 230pt;
	padding: 0 8pt 24pt 8pt;
	@media (max-width: 1023px) {
		width: 100%;
	}
}

.section-simulation .content-left.info-text {
	padding: 0 1.5rem 3rem 0;
	font-size:10pt;
}


.section-be-planner {
	background-color: gray;
	position: relative;
	width: 100vw;
	display: inline-flex;
	min-height: $section-height;
}

.section-be-planner .content-left {
	background-color: #91afa8;
	//min-height:$header-height;
}

.section-be-planner .content-right {
	background-color: #9cbeb6;
	//min-height:$header-height;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.section-be-planner .content {
	display: grid !important;
	grid-template-columns: auto auto auto;
	@media (max-width: 1023px) {
		grid-template-columns: auto;
	}
}

.section-be-planner .image-item {
	//min-width: 33%;
	//padding: 0 24pt 0pt 0pt;
	@media (max-width: 1023px) {
		padding: 0 0 1rem 0;
		width: 100%;
	}
}

.section-be-planner .image {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	margin: 0px auto;
	height: 20vh;
	width: 20vh;
	@media (max-width: 1023px) {
		min-width: calc(100vw - 3rem);
		max-width: calc(100vw - 3rem);
		//min-width: 20vw;
		//max-width: 20vh;
		min-height: 20vh;
		max-height: 20vh;
	}
}

.section-be-planner .image-wide {
	height: 20vh;
	width: 29.4vh;
	@media (max-width: 1023px) {
		height: calc((100vw - 3rem) / 1.469);
		min-height: auto;
		max-height: none;
	}
}

.section-be-planner .image-aplicacao {
	background-image: url("../../../images/static/beplanner/capacitacao.jpg");
}

.section-be-planner .image-aplicacao-2 {
	background-image: url("../../../images/static/beplanner/capacitacao-2.jpg");
}

.section-be-planner .image-simplicidade {
	background-image: url("../../../images/static/beplanner/simplicidade.jpg");
	background-position: top 25% left 100%;
}

.section-be-planner .image-sustentabilidade {
	background-image: url("../../../images/static/beplanner/sustentabilidade.jpg");
	background-position: top 25% left 100%;
}

.section-be-planner .image-sustentabilidade-2 {
	background-image: url("../../../images/static/beplanner/sustentabilidade-2.png");
}

.section-be-planner .image-comunidade {
	background-image: url("../../../images/static/beplanner/comunidade.jpg");
	background-position: top 10% left 100%;
}

.section-be-planner .image-comunidade-2 {
	background-image: url("../../../images/static/beplanner/comunidade-2.jpg");
}

.section-be-planner .image-block .text {
	padding-top:1.5rem;
	@media (max-width: 1023px) {
		padding:1rem 0 0 0;
	}
}

.section-be-planner .image-block {
	padding: 0 0 0 0;
	flex-direction: column;
	@media (max-width: 1023px) {
		//flex-direction: row;
	}
}

.section-be-planner .partners {
	padding-right: 1rem;
	@media (max-width: 1023px) {
		//padding-right: 1.5rem;
		padding-bottom: 1.5rem;
		padding-right: 0;
	}
}

.section-be-planner .partner-container {
	background-color: #8dc0b7;
	justify-content: space-around;
	width: 100%;
	margin:1rem 0 0 0;
	padding: 0 0 0 0;
	flex-wrap: wrap;
	@media (max-width: 1023px) {
		//flex-direction: row;
	}
}

.section-be-planner .partner-block {
	//min-width: 200px;
	padding: .5rem 1rem .5rem 1rem;
	flex-direction: column;
	@media (max-width: 1023px) {
		//flex-direction: row;
		min-width: 100%;
		margin-bottom: 1rem;
	}
}

.section-be-planner .content-left.info-text {
	padding: 0 1.5rem 3rem 1.5rem;
	font-size:10pt;
}


///////////

.section-testimonials {
	background-color: gray;
	position: relative;
	width: 100vw;
	display: inline-flex;
	min-height: $section-height;
}

.section-testimonials .content-left {
	background-color: #e3e1dc;
	//min-height:100vh;
}

.section-testimonials .content-right {
	background-color: #f1efe9;
	//min-height:$header-height;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.section-testimonials .content {
	padding-top: 1rem;
	column-gap: 20px;
	display: grid !important;
	grid-template-columns: auto auto auto;
	@media (max-width: 1023px) {
		padding-top: 1rem;
		grid-template-columns: auto;
	}
}


.section-testimonials .image-item {
	//min-width: 33%;
	padding-bottom: 30px;
	row-gap: 20px;
	flex-grow: 1;
	text-align: center;
	@media (max-width: 1023px) {
		padding: 0 0 1rem 0;
		width: 100%;
	}
}

.section-testimonials .image {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	border-radius: 5px;
	height: 30vh;
	width: 30vh;
	@media (max-width: 1023px) {
		//min-width: calc(100vw - 3rem);
		//max-width: calc(100vw - 3rem);
		min-width: 25vw;
		max-width: 25vh;
		min-height: 30vh;
		max-height: 30vh;
	}
}

.section-testimonials .image-block .text {
	padding-top: .5rem;
	@media (max-width: 1023px) {
		padding:1rem 0 0 0;
	}
}

.section-testimonials .image-block {
	padding: 0 0 0 0;
	flex-direction: column;
	@media (max-width: 1023px) {
		//flex-direction: row;
	}
}

.section-testimonials .content-left.info-text {
	padding: calc(25vh - 3rem) 1.5rem 1.5rem 1.5rem;
	text-align: right;
	font-size:10pt;
}


.section-testimonials .image-rogerio-saltes {
	background-image: url("../../../images/testimonials/rogerio-saltes.png");
	background-position-y: 40%;
}

.section-testimonials .image-fernanda-prado {
	background-image: url("../../../images/testimonials/fernanda-prado.png");
	background-position-y: 80%;
}

.section-testimonials .image-rodrigo-ferro-1 {
	background-image: url("../../../images/testimonials/rodrigo-ferro-1.png");
}

.section-testimonials .image-rodrigo-ferro-2 {
	background-image: url("../../../images/testimonials/rodrigo-ferro-2.png");
}

.section-testimonials .image-caporal {
	background-image: url("../../../images/testimonials/caporal.jpg");
}

.section-testimonials .image-cantiere {
	background-image: url("../../../images/testimonials/cantiere.jpg");
}

////////////////////


.section-form-planner {
	background-color: gray;
	position: relative;
	width: 100vw;
	display: inline-flex;
	min-height: $section-height;
}

.section-form-planner .content-left {
	background-color: #056672;
	//min-height:100vh;
}

.section-form-planner .content-right {
	background-color: #018a9b;
	//min-height:$header-height;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.section-form-planner .content-left.info-text {
	padding: 0 1.5rem 1.5rem 1.5rem;
}
/////////////////

.section-footer {
	background-color: gray;
	position: relative;
	width: 100vw;
	display: inline-flex;
	overflow: hidden;
}

.section-footer .content-left {
	background-color: #9cbeb6;
	//min-height:100vh;
	padding: 1.5rem 1.5rem 0 1.5rem;
}

.section-footer .menu-container,
.section-footer .social-menu-container
{
	justify-content: flex-start;
	align-items: flex-start;
	@media (max-width: 1023px) {
		width: 100%;
		justify-content: center;
		align-items: center;
	}
}
.section-footer .content-right {
	background-color: #9cbeb6;
	//min-height:$header-height;
	padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}

.menu-options {
	background-color: #193330;
	position: fixed;
	display: flex;
	width: 100vw;
	height: 100vh;
	//min-height: 660px;
	min-height: 100vh;
	z-index:10;
}

.link-dark {
    color: #101011;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    transition-property: color;
    transition-duration: .7s;
    transition-timing-function: cubic-bezier(.5,0,0,1);
}

.link-dark, .link-dark:active, .link-dark:focus, .link-dark:hover, .link-dark:link, .link-dark:visited {
    text-decoration: none!important;
    outline: 0!important;
}

.link-dark:hover {
	color:white !important;
}

.link-green {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    transition-property: color;
    transition-duration: .7s;
    transition-timing-function: cubic-bezier(.5,0,0,1);
}

.top-menu-options {
	top:1.5rem;
	right:.1rem;
	width: 100vw;
	left:0;
	text-align: right;
	justify-content: flex-end;
	align-items: flex-end;
}


.menu-options {
	opacity: 0;
	display: none;
	z-index:0;
    transition-property: opacity;
    transition-duration: .7s;
    transition-timing-function: cubic-bezier(.5,0,0,1);
	pointer-events: none;
}

.menu-options.appear {
	opacity: 1;
	z-index:999;
	pointer-events: unset;
}

.preloader, .home-container {
	opacity: 0;
    transition-property: opacity;
    transition-duration: .7s;
    transition-timing-function: cubic-bezier(.5,0,0,1);
}

.preloader {
	opacity:1;
}

.preloader-disappear {
	opacity: 0;
	pointer-events: none !important;
}

.home-hidden {
	opacity: 0;
}

.home-appear {
	opacity: 1;
}

.link-green, .link-green:active, .link-green:focus, .link-green:hover, .link-green:link, .link-green:visited {
    text-decoration: none!important;
    outline: 0!important;
}

.link-green:hover {
	color:$fiduc-green-light !important;
}

.word-wrapper {
	white-space: nowrap;
}

#page-home {
	position: absolute;
	width: 100vw;
	display: inline-grid;
	top:0;
	background-color: $fiduc-neutral;
}


//#page-home .section-intro .backgrounds {
//    pointer-events: none;
//    user-select: none;
//    z-index: 1;
//}

#page-home .background-left {
	left: 0;
	width: calc(50vw - #{$split-page});
}

#page-home .background-right {
	right: 0;
	width: calc(50vw + #{$split-page});
}


$content-width : 29rem;

.content-left {
	position:relative;
	top:0;
	display: inline-block;
	width: calc(50vw - #{$split-page});
	height: 100%;
	@media (max-width: 1023px) {
		display: none !important;
	}
}

.content-left-text {
	padding-right:1.5rem;
	padding-left:.5rem;
	padding-top: 40px;
	padding-bottom:2rem;
	font-size:10pt;
}

.section-intro .content-left-text {
	padding-top: calc(65px + (2rem + 33px))
}

.hide-in-mobile {
	@media (max-width: 1023px) {
		display: none !important;
	}
}

.hide-in-desktop {
	@media (min-width: 1022px) {
		display: none !important;
	}
}


.content-right {
	display: inline-block;
	position:relative;
	top:0;
	width:calc(100vw - calc(50vw - #{$split-page}));
	height: 100%;
	@media (max-width: 1023px) {
		width: 100vw;
	}
}

 .slogan-description {
	padding-right:1.5rem;
	padding-left:.5rem;
	//padding-bottom:5rem;
	padding-top:4rem;
	padding-bottom:2rem;
	font-size:10pt;
	@media (max-width: 1023px) {
		padding: 1rem 0 1rem 0;
	}
}

.section-intro .slogan-description-desktop {
	@media (max-width: 1023px) {
		padding-bottom:2rem;
	}
}



.fiduc-slogan-bottom {
	font-size:16px;
	padding-left:1.5rem;
	padding-bottom:1.5rem;
	line-height: 21px;
	@media (max-width: 1023px ) {
		padding-left:0rem;
	}
}


.background-intro {
	background-image: url("../../../images/background/background-intro.jpg");
	background-repeat: no-repeat;
	width: 100vw;
	height: 100vh;

	//background-size: calc(100vw - #{$split-page}) auto;
	background-position: top 0px left 100%;
	background-size: cover;

	//@media (max-width: 1023px) {
	//	border:solid 10px blue;
	//}

	//@media (min-width: 1123px) {
	//	background-position: right #{$split-page} top 0 ;
	//	background-size: 100% auto;
	//	border:solid 10px yellowgreen;
	//}
	//
	//@media (min-width: 1323px) {
	//	background-position: right 381px top 0 ;
	//	background-size: auto 100%;
	//	border:solid 10px #b58739;
	//}

	@media (max-aspect-ratio: 7/6) {
		//border:solid 10px red;
		background-size: auto 100%;
		background-position: bottom 0 right 50%;
	}
}

.xbackground-intro IMG {
	//border: solid 10px red;
	width: 100%;
	height: auto;
	@media (max-aspect-ratio: 7/6) {
		//border:solid 10px red;
		width: unset;
		transform:translateX(-214px) ;
		//transform: scale(1);
		height: 100vh !important;
	}
}

.background-intro {
	//object-fit: fill;
}

//.header-register-button {
//	margin:0rem 2rem 0rem 2rem ;
//	@media (max-width: 1023px ) {
//		margin:0rem;
//		height: 2rem !important;
//		//display: none !important;
//	}
//
//}

.intro-message {
	top:0;
	padding-top:8rem;
	padding-left:1.5rem;
	padding-right:1.5rem;
	line-height: 1;
	//padding-top:9rem;
	//padding-left:2rem;
	display: flex;
	width: 100%;
	height: 100%;
	@media screen and (max-width: 1023px) {
		padding-top: 9rem;
	}
	@media screen and (orientation: landscape) and (max-device-height: 360px)  {
		padding-top: 5rem ;
	}
}

.line-height-13 {
	line-height: 1.3;
}

.obj-fit-cover {
	object-fit: cover;
}

.ps-6 { padding-left: 4rem !important;}
.ps-7 { padding-left: 5rem !important;}
.ps-7-5 { padding-left: 5.5rem !important;}

.ps-8 { padding-left: 6rem !important;}
.pe-6 { padding-right: 3rem !important;}

.ps-v-8 { padding-left: 2vw !important;}
.me-v-8 { margin-right: 4vw !important;}

.me-f-22 { margin-right: 22pt !important;}

.me-f-32 { margin-right: 32pt !important;}
.me-f-16 { margin-right: 16pt !important;}

.no-pointer-events {
	pointer-events: none;
}


.animated-circle a {
	color: unset !important;
}

.logo-circle.animated-circle {
	@media (max-width: 1023px) {
		width: auto;
		transform: scale(.6);
	}
}

.logo-menu-options {
	@media (max-width: 1023px) {
		width: auto;
		transform: scale(.8) !important;
	}
}

.social-menu-options {
	bottom:0;
	left:0;
	width:100vw;
}


.top-arrow.animated-circle a
{
	transition-property: transform;
	transition-duration: 1s;
    transition-timing-function: cubic-bezier(.5,0,0,1);
}

.top-arrow.animated-circle a:hover {
	transform: scale(1.25);
}

.top-arrow.animated-circle {
	@media (max-width: 1023px) {
		width: auto;
		transform: scale(.75);
	}
	svg {
		&:nth-child(1) {
			width: 36pt;
			height: auto;
		}
		&:nth-child(2) {
			width: 12pt;
			height: auto;
		}
	}
}

.animated-circle {

	a {
		position: relative;
		display: inline-block;

		svg {
			display: block;

			&:nth-child(1) {
				position: relative;
				overflow: visible;
				transform: rotate(-90deg);
				z-index: 1;

				circle {
					stroke-width: 2px;
					stroke-dasharray: 420;
					stroke-dashoffset: 420;
					transition-property: stroke-dashoffset;
					transition-duration: 1s;
					transition-timing-function: ease-in-out;
				}
			}

			&:nth-child(2) {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				opacity: 0;
				transition-property: opacity;
				transition-duration: .7s;
				transition-timing-function: ease-in-out;
				z-index: 2;
			}
		}
	}

	&.appear a svg {
		&:nth-child(1) circle {
			stroke-dashoffset: 0;
		}

		&:nth-child(2) {
			opacity: 1;
			transition-delay: 0.1s;
		}
	}
}


.form-recrut .form-field,
.form-recrut .form-control,
.form-recrut .form-field .icon I,
.form-recrut .form-field-floating-label-focus,
.form-recrut .form-field-floating-label-blur,
.form-recrut INPUT
{
	background-color: transparent !important;
	color:white;
}

.form-recrut input:-webkit-autofill,
.form-recrut input:-webkit-autofill:hover,
.form-recrut input:-webkit-autofill:active,
.form-recrut input:-webkit-autofill:focus {
	background-color: #018a9b !important;
	color: white !important;
	-webkit-box-shadow: 0 0 0 1000px #018a9b inset !important;
	box-shadow: 0 0 0 1000px #018a9b inset !important;
	-webkit-text-fill-color: white !important;
	//border:inherit !important;
	font-weight:inherit !important;
	font-family:inherit !important;
	border-top-color: #018a9b !important;
	border-left-width: 0 !important;
	border-right-width: 0 !important;
}

.form-recrut {
	color:black;
}

.form-recrut .form-field-floating-label-focus,
.form-recrut .form-field-floating-label-blur
{
	//font-family: "PP Neue Machina" !important;
}

.form-recrut INPUT {
	border-top-width: 0 !important;
	border-left-width: 0 !important;
	border-right-width: 0 !important;
	box-shadow: unset !important;
}


.form-field-type-select2 .form-control-container.is-invalid,
.form-field-type-radiogroup FIELDSET.is-invalid, .form-group TEXTAREA.is-invalid {
	color:$fiduc-green-light !important;
	border-color:$fiduc-green-light !important;
}

.form-group INPUT.is-invalid {
	color: white !important;
	border-color:$fiduc-green-light !important;
}

.form-field-error-message {
	color:$fiduc-green-light !important;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%2334fd71'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%2334fd71' stroke='none'/%3e%3c/svg%3e") !important;
}

.form-field-floating-label-focus, .form-field-floating-label-blur {
	font-weight: 500;
}
.form-field-floating-label-focus {
	padding-left : 0;
}

.form-control {
	padding: 0.17rem 0.75rem;
}

.form-control .form-control-container {
	min-height: 3.5rem;
	max-height: 3.5rem;
}

.custom-form .form-control {
	//padding-top: 20pt;
	border-top-width: 0 !important;
	border-left-width: 0 !important;
	border-right-width: 0 !important;
	box-shadow: unset !important;
	min-height: 3.5rem;
}

.select2-selection__indicator-separator {
	border-color: red !important;
}

.select2-selection__dropdown-indicator {
	fill: red !important;
}
.w-sm-100 {
	@media (max-width: 1023px) {
		width: 100%;
	}

}

.form-field-type-checkaccept fieldset{
	//margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
	border:0px !important;
}

